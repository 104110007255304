import Footer from "../../../Components/Footer.js";
import LoginForm from "../../../Components/LoginForm.js";
import "./index.scss";
import React, { useEffect } from "react";


function Login() {
  return [<LoginForm/>, <Footer/>]
}

export default Login;
