import React, { useEffect } from "react";

function JoinForm() {
  return (
    <div className="container">
      <div className="join-wrapper">
        <h1 className="heading-join">VECTOR</h1>
        
        <div className="signup"><div className="heading">
          {/* <h1 className="titleJoin">TOP G RUSSIA</h1> */}
          <p className="description">
            АВТОРИЗАЦИЯ
          </p>
        </div>
          <div className="inputs">
            <input
              className="input"
              type="text"
              name="login"
              placeholder="ЛОГИН"
            />
            <input className="input" type="password" placeholder="ПАРОЛЬ" />
          </div>
          <div className="button">ВЫХоД ИЗ МАТРИЦЫ</div>
        <div className="heading">
          {/* <h1 className="titleJoin">TOP G RUSSIA</h1> */}
          <p className="description">
            ВСТУПЛЕНИЕ ПРОИЗВОДИТСЯ ТОЛЬКО ПО ПРИГЛАШЕНИЯМ. ВЫ МОЖЕТЕ ОСТАВИТЬ ЗАЯВКУ, МЫ СВЯЖЕМСЯ ПОЗЖЕ.
          </p>
        </div>
        </div>
      </div>
    </div>
  );
}

export default JoinForm;
