//styles
import "./styles/App.scss";
// import "./styles/themes.scss";

import React from "react";
// import useColorScheme from "./scripts/useColorScheme";

// components
// import Footer from "./components/Footer";

// pages
import Main from "./Pages/Main";
import Token from "./Pages/Token";

// router
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUp from "./Pages/Auth/SignUp";
import Login from "./Pages/Auth/Login";
import Land from "./Pages/Vector/Land";
import Courses from "./Pages/Vector/Courses";
import Course from "./Pages/Vector/Course";
import CommunityPage from "./Pages/Community";
import NetworkPage from "./Pages/Network";

const max = "Магистр Бизнеса";
const vovan = "Магистр Фитнеса";
const sanya = "Магистр Крипты";
const boris = "Магистр Товарки";

// const data = [
//   {
//     id: "1",
//     title: "Speed",
//     course: "Hustlers University",
//     module: "Financial Wizardy",
//     speaker: "Andrew Tate",
//     src: "https://youtu.be/j5gTQ4CUxXE?si=Il3CiSXH_XBghLbK",
//     cover:
//       "/hu/hu1.jpg",
//     description: <div class="container">
//     <h1>Скорость - ключ к успеху</h1>
  
//     <h2>Введение</h2>
  
//     <div class="item">
//       <ul>
//         <li><strong>Значение скорости в бизнесе и жизни.</strong></li>
//         <li><strong>Путаница между скоростью и качеством выполнения задач.</strong></li>
//         <li><strong>Первичная цель бизнеса — эффективность и оперативность.</strong></li>
//       </ul>
//     </div>
  
//     <h2>Примеры и иллюстрации</h2>
  
//     <div class="item">
//       <span class="icon">🛫</span>
//       <div>
//         <span class="title">Аналогия с самолетом:</span>
//         <span class="description"> Даже при выходе из строя двигателей, инерция и подъемная сила позволяют ему продолжать полет.</span>
//       </div>
//     </div>
  
//     <div class="item">
//       <span class="icon">⚡️</span>
//       <div>
//         <span class="title">Понятие инерции в бизнесе:</span>
//         <span class="description"> Постоянное движение вперед обеспечивает стабильность и успех.</span>
//       </div>
//     </div>
  
//     <div class="item">
//       <span class="icon">🫨</span>
//       <div>
//         <span class="title">Атака vs оборона:</span>
//         <span class="description"> В бизнесе лучшая защита — это активная атака.</span>
//       </div>
//     </div>
  
//     <h2>Ключевые моменты</h2>
  
//     <div class="item">
//       <ul>
//         <li><strong>😓 Необходимость быстрого реагирования и действий для достижения целей.</strong></li>
//         <li><strong>🟢 Важность оперативности в создании рекламы, заключении сделок и развитии бизнеса.</strong></li>
//         <li><strong>🔴 Потери, связанные с замедлением процессов и упущенными возможностями.</strong></li>
//         <li><strong>🟡 Пример из личного опыта в продажах и управлении компанией, демонстрирующий влияние скорости на успех и выживаемость.</strong></li>
//         <li><strong>🔼 Стремление к оперативности и постоянное движение вперед — ключевой фактор успеха в бизнесе и жизни.</strong></li>
//       </ul>
//     </div>
  
//     <div class="important">
//       <h2>Запомнить</h2>
//       <p>Скорость и оперативные действия играют важную роль в достижении успеха в бизнесе, позволяя избежать упущенных возможностей и достичь желаемых результатов.</p>
//     </div>
  
//   </div>
//   ,
//     video: <iframe title="Speed" src="https://vk.com/video_ext.php?oid=-217322611&id=456239469&autoplay=0" className="module-video" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
//   },
//   {
//     id: "2",
//     title: "Money In",
//     speaker: "Andrew Tate",
//     course: "Hustlers University",
//     module: "Financial Wizardy",
//     src: "https://youtu.be/j5gTQ4CUxXE?si=Il3CiSXH_XBghLbK",
//     video: <iframe title="Speed" src="https://vk.com/video_ext.php?oid=-217322611&id=456239470&autoplay=0" className="module-video" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>,
//     cover:
//     "/hu/hu2.jpg",
//     // description: [
//     //   <h3>Введение:</h3>,
//     //   <ul>Бизнес является бизнесом только тогда, когда клиенты платят за ваши продукты или услуги. </ul>,
//     //   <div className="space"/>,
//     //   <h3>Ключевые моменты:</h3>,
//     //   <ul>🖥 Как создать с 0 бренд косметики и начать зарабатывать сегодня</ul>,
//     //   <ul>💸 Бизнес определяется поступлением денег, а не внешними атрибутами, такими как логотип, регистрация или веб-сайт.</ul>,
//     //   <ul>💸 Получение дохода должно быть основным направлением деятельности любого бизнеса.</ul>,
//     //   <ul>⭐️ Отдавайте приоритет денежному потоку и получению дохода, а не ненужным расходам.</ul>,
//     //   <ul>👀 Протестируйте небольшие объемы перед расширением, чтобы снизить риски и обеспечить жизнеспособность.</ul>,
//     //   <ul>💵 Кэш является источником жизненной силы бизнеса и должны лежать в основе всех стратегических решений.</ul>,
//     //   <div className="space"/>,
//     //   <h3>Извлеченный урок:</h3>,
//     //   <ul>Понимание того, что бизнес процветает за счет денежного потока, и приоритетность получения дохода имеют решающее значение для предпринимательского успеха. Сосредоточившись на привлечении денег и тестировании малого бизнеса, предприниматели могут создавать устойчивые предприятия, которые нацелены на рост и успех в долгосрочной перспективе.</ul>,
//     // ],
//   },
//   {
//     id: "9",
//     title: "Урок 1",
//     course: "Бизнес",
//     module: "Введение",
//     src: "https://youtu.be/j5gTQ4CUxXE?si=Il3CiSXH_XBghLbK",
//     cover: "/b/b1.jpeg",
//     description: [
//       "Развитие бизнеса включает в себя понимание целевой аудитории, рыночных возможностей и эффективных каналов охвата для достижения успеха ⭐️ ",

//       "Я начну серию постов с общего понимания того, в каких направлениях необходимо развивать бизнес и получать соответствующие компетенции ",

//       "В зависимости от организации, выделяют следующие ветви: ",

//       "📊 Исследование и анализ рынка ",
//       "Выявление новых рыночных возможностей и разработка эффективных стратегий",

//       "⌛ Продажи и определение лидов",
//       "Поиск потенциальных покупателей и преобразование их в клиентов ",
//       "🤝 Стратегические партнерства и альянсы",
//       "Формирование сотрудничеств, для взаимовыгодных возможностей ",
//       "💡 Разработка продуктов и инноваций",
//       "Проведение маркетинговых исследований, сбор отзывов клиентов ",
//       "🎮 Управление взаимоотношения с клиентами",
//       "Реализация инициатив по удержанию клиентов, программы лояльности, стимулирование повторных сделок ",
//       "🗓 Стратегическое планирование и бизнес-моделирование",
//       "Выявление возможностей роста, постановка целей и стратегий",
//       "🖥 Управление брендом и маркетинг",
//       "Создание эффективных маркетинговых кампаний ",
//       "📈 Финансовый анализ и финансирование",
//       "Изучение вариантов финансирования, обеспечение инвестиций, поиск возможных грантов ",
//       "🆕 Инновации и новые технологии",
//       "Оценка потенциального влияния прорывных технологий и их интеграция в деятельность организации ",
//       "Все эти направления необходимо развивать для роста компании.",

//       "Если возникают вопросы пишите в комментариях 💬",
//     ],
//     speaker: max,
//   },
//   {
//     id: "3",
//     title: "Язык Тела: ИНТРО",
//     speaker: "Andrew Tate",
//     course: "Hustlers University",
//     module: "Body Language",
//     src: "https://youtu.be/j5gTQ4CUxXE?si=Il3CiSXH_XBghLbK",
//     cover: "/hu/bd1.jpg",
//     description: [
//       "Введение:",
//       "• Значение скорости в бизнесе и жизни.",
//       "• Путаница между скоростью и качеством выполнения задач.",
//       "• Первичная цель бизнеса — эффективность и оперативность.",

//       "Примеры и иллюстрации:",
//       "🛫 Аналогия с самолетом: даже при выходе из строя двигателей, инерция и подъемная сила позволяют ему продолжать полет.",
//       "⚡️ Понятие инерции в бизнесе: постоянное движение вперед обеспечивает стабильность и успех.",
//       "🫨 Атака vs оборона: в бизнесе лучшая защита — это активная атака.",

//       "Ключевые моменты:",
//       " 😓 Необходимость быстрого реагирования и действий для достижения целей.",
//       "🟢Важность оперативности в создании рекламы, заключении сделок и развитии бизнеса.",
//       "🔴Потери, связанные с замедлением процессов и упущенными возможностями.",
//       "🟡Пример из личного опыта в продажах и управлении компанией, демонстрирующий влияние скорости на успех и выживаемость.",
//       "🔼Стремление к оперативности и постоянное движение вперед — ключевой фактор успеха в бизнесе и жизни.",

//       "Запомнить:",
//       "Скорость и оперативные действия играют важную роль в достижении успеха в бизнесе, позволяя избежать упущенных возможностей и достичь желаемых результатов.",
//     ],
//   },
//   {
//     id: "4",
//     cover: "/f/l1.jpeg",
//     title: "Разминка",
//     speaker: vovan,
//     href: "/fitnes/1",
//     course: "Фитнес",
//     module: "Разминка",
//   },
//   {
//     id: "5",
//     cover: "/d/d1.jpeg",
//     title: "Товарный бизнес. Основы.",
//     speaker: boris,
//     course: "Товарка",
//     module: "Введение",
//     href: "/tovarka/1",
//     description: <div class="container">
//     <h1>Что мы будем изучать?</h1>
  
//     <p>Заниматься мы будем товарным бизнесом: 
//       <ul>
//         <li>Дропшиппинг</li>
//         <li>Классическая модель товарного бизнеса</li>
//       </ul>
//     </p>
  
//     <p>Этот пост направлен на знакомство с базой дропшиппинга.</p>
  
//     <h2>Что такое дропшиппинг? 🌐</h2>
  
//     <div class="item">
//       <span class="icon">🌐</span>
//       <div>
//         <span class="title">Дропшиппинг</span>
//         <span class="description"> - модель товарного бизнеса, основная идея которой заключается в отсутствии личного склада с товаром. То есть, человек, не имея товара в наличии, продает покупателю.</span>
//       </div>
//     </div>
  
//     <h2>Как работает дропшиппинг? 🤔</h2>
  
//     <div class="item">
//       <span class="icon">🤝</span>
//       <div>
//         <span class="description">Ты договариваешься с поставщиком о том, что он дает тебе около оптовую цену (чуть выше), а ты регулярно у него покупаешь товар.</span>
//       </div>
//     </div>
  
//     <h2>Выгода продавца 🙂</h2>
  
//     <div class="item">
//       <span class="icon">💸</span>
//       <div>
//         <span class="description">У него появляется постоянный клиент, который покупает товар выше оптовой цены, то есть продавец имеет большую маржинальность с одного товара.</span>
//       </div>
//     </div>
  
//     <h2>Твоя выгода 💸</h2>
  
//     <div class="item">
//       <span class="icon">✅</span>
//       <div>
//         <span class="list">
//           <li>у тебя недостаточно денег для закупа товара.</li>
//           <li>нет никаких остатков.</li>
//           <li>можешь продавать много товара одновременно, а значит оборот выше.</li>
//         </span>
//       </div>
//     </div>
  
//     <p>А где выше оборот – там и прибыль больше.</p>
  
//     <h2>Терминология</h2>
  
//     <ul>
//       <li><strong>Конечный потребитель</strong> — клиент магазина, который покупает товар для себя.</li>
//       <li><strong>Дропшиппер</strong> — продавец, который контактирует с конечным потребителем от своего имени, но физически этот товар не хранит у себя и не доставляет. Его задача — создать площадку для продажи и приводить туда клиентов.</li>
//       <li><strong>Поставщик</strong> — оптовая компания, импортер или производитель продукции, который отправляет товар конечному потребителю.</li>
//     </ul>
  
//   </div>
  
//   },
//   {
//     id: "6",
//     cover: "/c/1.jpeg",
//     title: "Разбор Блокчейна",
//     course: "Крипта",
//     module: "Блокчейн По Полочкам",
//     speaker: sanya,
//     href: "/business/1",
//     description: <div class="container">
//     <h1>РАЗБОР БЛОКЧЕЙНА ПО ПОЛОЧКАМ Ч1.</h1>
  
//     <h2>Что же такое блокчейн? 🔗</h2>
  
//     <div class="item">
//       <span class="icon">🌐</span>
//       <div>
//         <span class="title">Блокчейн</span>
//         <span class="description"> - это четкая последовательность блоков, где в каждом из блоков хранится несколько транзакций.</span>
//       </div>
//     </div>
  
//     <div class="item">
//       <span class="icon">🚀</span>
//       <div>
//         <span class="title">Транзакция</span>
//         <span class="description"> - это операция, при которой происходит передача крипты или другой информации между кошельками.</span>
//       </div>
//     </div>
  
//     <div class="item">
//       <span class="icon">🌐</span>
//       <div>
//         <span class="title">Сеть</span>
//         <span class="description"> - это совокупность участников сети и их взаимодействий(компьютеров, майнеров).</span>
//       </div>
//     </div>
  
//     <div class="item">
//       <span class="icon">🤨</span>
//       <div>
//         <span class="title">Зачем нам блокчейн?</span>
//         <ul>
//           <li>Создание Приложений, политика которых направлена на открытость кода</li>
//           <li>Анонимизация</li>
//           <li>Защита средств</li>
//           <li>Безопасность</li>
//           <li>Хранилище информации (База данных, называйте как удобнее)</li>
//         </ul>
//       </div>
//     </div>
//   </div>
  
  
//   },
//   {
//     id: "7",
//     cover: "/d/d2.jpg",
//     title: "Классическая модель",
//     speaker: boris,
//     course: "Товарка",
//     module: "Введение",
//     href: "/tovarka/2",
//     description: <div class="container">
//     <h1>🔥 Что же такое классическая модель товарного бизнеса? Давайте разбираться.</h1>
  
//     <div class="item">
//       <span class="icon">🙄</span>
//       <div>
//         <span class="title">Классическая модель</span>
//         <span class="description"> - простыми словами – купил дешевле, продал дороже. Запомните это золотое правило.</span>
//       </div>
//     </div>
  
//     <div class="item">
//       <span class="icon">😶‍🌫️</span>
//       <div>
//         <span class="title">Разница с дропшиппингом</span>
//         <span class="description"> -  в том, что здесь товар находится у тебя, он твой. После покупки товара зависимость от поставщика пропадает, и ты полностью сам занимаешься процессом продажи.</span>
//       </div>
//     </div>
  
//     <div class="item">
//       <span class="icon">🤔</span>
//       <div>
//         <span class="title">Преимущество для продавца</span>
//         <span class="description"> -  продавцу выгодно продать сразу несколько товаров, тк он увеличивает оборот, а значит и прибыль, деленную на время. Пример: оптовик продал тебе по схеме дропшиппинга за раз только одну футболку и получил прибыль 100 рублей. Но когда он продаст тебе не 1, а сразу 5, его прибыль с одной футболки уменьшится на 50 рублей, но при этом общая прибыль станет 250 рублей. Поэтому и выгодно.</span>
//       </div>
//     </div>
  
//     <div class="item">
//       <span class="icon">🤑</span>
//       <div>
//         <span class="title">Выгода для тебя</span>
//         <span class="description"> -  а для тебя выгода заключается в том, что продаешь ты этот товар по той же цене, что и продавал бы на дропшиппинге, но только покупал ты дешевле. А значит, ты получаешь больше профита.</span>
//       </div>
//     </div>
  
//     <div class="item">
//       <span class="icon">🥸</span>
//       <div>
//         <span class="title">Схема</span>
//         <span class="description"> -  в этой схеме так же присутствует Конечный потребитель и Поставщик. Но теперь, ты уже не дропшиппер, а продавец. Поздравляю с повышением!</span>
//       </div>
//     </div>
//   </div>
  
//   },
//   {
//     id: "8",
//     cover: "/c/2.jpg",
//     title: "Аккаунты и Смарт-контракты",
//     course: "Крипта",
//     module: "Блокчейн По Полочкам",
//     speaker: sanya,
//     href: "/crypto/2",
//     description: <div class="container">
//     <h1>РАЗБОР БЛОКЧЕЙНА ПО ПОЛОЧКАМ Ч2</h1>
  
//     <div class="item">
//     <span class="icon">👛</span>
//         <span class="title">Аккаунты/Кошельки и Смарт-Контракты</span>
//         <span class="description"> - Чтобы рассказать подробнее про взаимодействие с блокчейном(криптой), нам необходимо затронуть еще очень важную вещь.</span>
   
//     </div>
  
//     <div class="item">
//     <span class="icon">❓</span>
//         <span class="title">Что такое Аккаунт?</span>
//         <span class="description"> - Это кошелек, от имени которого вы взаимодействуйте с сетью.</span>
   
//     </div>
  
//     <div class="item">
//     <span class="icon">✅</span>
//         <span class="title">Что он имеет?</span>
//         <ul>
//           <li>Публичный Адрес - именно его вы отправляете своему другу, чтобы получить платеж</li>
//           <li>Секретный Ключ(Private Key) - это пароль кошелька, который вам необходим для использования вашего аккаунта</li>
//           <li>Текущий Баланс - сколько у вас Основного токена сети(Ethereum - ETH, BITCOIN - BTC)</li>
//           <li>Seed Фраза - это набор слов, шифрующий ваш секретный ключ</li>
//         </ul>
//     </div>
  
//     <div class="item">
//     <span class="icon">➡️</span>
//         <span class="title">Для чего нужны Аккаунты?</span>
//         <ul>
//           <li>Это ваш счет/аккаунт как в банке</li>
//           <li>Для взаимодействия и создания транзакций(Перевод крипты на другие аккаунты или же вызов функций смарт-контрактов для выполнения действий)</li>
//         </ul>
 
//     </div>
  
//     <div class="item">
//     <span class="icon">❓</span>
//         <span class="title">Что такое Смарт-контракт?</span>
//         <span class="description"> - Это точно такой же аккаунт, но у него главное отличие:</span>
//       </div>
//       <div class="item">
//       <div>
//         <span class="title">Присутствие КОДА</span>
//         <span class="description"> - (В коде прописан весь функционал контракта) ➡️ Соответсвенно управление им автоматизировано</span>
//       </div>
//     </div>
  
    
  
//     <div class="item">
//     <span class="icon">➡️</span>
//         <span class="title">Для чего нужны Контракты?</span>
//         <span class="description"> - Для создания приложений и web3 сервисов(подробнее в следующих постах)</span>
   
//     </div>
  
//     <div class="warning">
//       ⚠️ *ВАЖНЫЕ ВЕЩИ*
//       <ul>
//         <li>Имея Секретный ключ или Seed Фразу любой человек может управлять аккаунтом</li>
//         <li>Имея ваш адрес, человек может просмотреть ваш баланс и все транзакции(об этом расскажу следующих уроках)</li>
//       </ul>
//     </div>
  
//   </div>
  
  
//   },
// ];

function App() {
  // theme references
  // const isDark = useColorScheme('dark');
  // const theme = isDark ? 'dark' : 'light';

  return (
    <div className={`App`}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/vector" element={<Land data={data}/>} /> */}
          {/* <Route path="/courses" element={<Courses />} /> */}
          {/* <Route path="/courses/:id" element={<Course data={data} />} /> */}
          <Route path="/community/" element={<CommunityPage />} />
          <Route path="/token/" element={<Token />} />
          <Route path="/network/" element={<NetworkPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
