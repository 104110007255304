import Footer from "../../../Components/Footer";
import Navbar from "../../../Components/Navbar";
import JoinForm from "../../../Components/JoinForm";
import "./index.scss";
import React, { useEffect } from "react";


function SignUp() {
  return [<JoinForm/>, <Footer/>]
}

export default SignUp;
