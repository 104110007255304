import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Community from "../../Components/Community";
import "./index.scss"

function CommunityPage() {
    

    return [<Navbar buttons={false}/>, <Community/>, <Footer/>]
}

export default CommunityPage