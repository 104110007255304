import React, { useEffect } from "react";

function JoinForm() {
  return (
    <div className="container">
      <div className="join-wrapper">
        <h1 className="heading-join">VECTOR</h1>
        
        <div className="signup"><div className="heading">
          {/* <h1 className="titleJoin">TOP G RUSSIA</h1> */}
          <p className="description">
            ВЫЙДИ ИЗ МАТРИЦЫ.
          </p>
        </div>
          <div className="inputs">
            <input
              className="input"
              type="text"
              name="first_name"
              placeholder="ИМЯ*"
            />
            <input disabled="true" className="input" type="email" placeholder="EMAIL*" />
            <input disabled="true" className="input" type="tel" placeholder="ТЕЛЕФОН*" />
          </div>
          <div className="button">Оставить заявку</div>
        <div className="heading">
          {/* <h1 className="titleJoin">TOP G RUSSIA</h1> */}
          <p className="description">
            ВСТУПЛЕНИЕ ПРОИЗВОДИТСЯ ТОЛЬКО ПО ПРИГЛАШЕНИЯМ. СЛЕДИТЕ ЗА НОВОСТЯМИ В <a href="https://t.me/topgrussian">КАНАЛЕ</a>, МЫ АНОНСИРУЕМ ОТКРЫТИЕ СКОРО...
          </p>
        </div>
        </div>
      </div>
    </div>
  );
}

export default JoinForm;
