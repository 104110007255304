import React from "react"
import img from "../icons/tate_em.jpg"



function Community() {
    return <div className="container" id="community">
        <div className="community-wrapper">
            <div className="community-title">
                <h1>Коммьюинити</h1>
            </div>
            <div className="community-courses">
                {/* <div className="row">
                    <div className="course-wrapper">
                        <div className="course img-1"/>
                    </div>
                    <div className="course-wrapper">
                        
                    <div className="course img-2"/></div>
                </div> */}
                {/* <div className="row">
                    <div className="course-wrapper">
                        
                    <div className="course img-3"/> </div>
                    <div className="course-wrapper">
                        
                    <div className="course img-4"/></div>
                </div> */}
            </div>
            <div className="community-description">
                <p>VECTOR открывает доступ к чату, где вы сможете познакомиться и начать развиваться быстрее.</p>
                {/* <p>G Club открыает вам доступ к мировым иноостранным экспепертам в своем бизнесеа, предпренимательстве, создании карьеры и биохакинге.</p> */}
            
            </div><div className="community-description">
                {/* <h3>ИНТЕРНЕТ ЗАРАБОТОК</h3> */}
                <h3>ОНЛАЙН ЗАРАБОТОК И НЕТВОРКИНГ</h3>
                {/* <p>G Club открыает вам доступ к мировым иноостранным экспепертам в своем бизнесеа, предпренимательстве, создании карьеры и биохакинге.</p> */}
            
            </div>
            <img src="/com.jpeg" className="interviewimg"/>
            <div className="button-wrapper chat-button">
            <a className="button" href="https://t.me/+b18B3tdE0Z80MzUy">ВСТУПИТЬ В ЧАТ</a>
            </div>
            <div className="rules">
                <div className="community-title"><h3>Community GuideLines</h3></div>
                {/* <p>G Club открыает вам доступ к мировым иноостранным экспепертам в своем бизнесеа, предпренимательстве, создании карьеры и биохакинге.</p> */}
                <div className="rule-section">
                    {/* <div className="rule-title"><h3>Правила для участников чата</h3></div> */}
                    <div className="rule-description">
 <div className="rule-subtitle"><h3>1. Условия действия правил чата</h3></div>
<tr/> 1.1. Пользователи, заходя в чат, принимают на себя добровольные обязательства беспрекословно соблюдать ниже перечисленные правила.
<tr/> 1.2. Незнание правил не освобождает от ответственности за их нарушения, убедительно рекомендуем с ними ознакомиться.
<tr/><div className="rule-subtitle"><h3>2. В чате разрешается:</h3></div>
<tr/>2.1. Общаться, шутить, делиться информацией и обсуждать темы, связанные по тематике чата, а также совершать любые другие действия не нарушающие п3.
<tr/>2.2. Помогать участникам чата, которые просят помощи в поиске информации и т.д.
<tr/>2.3. Делиться полезными ресурсами в ходе разговора (это не касается пункта 3.16).
<tr/>2.4. Просить помощь в написании кода, прилагая все необходимое, чтобы облегчить задачу участникам чата. 
<tr/>2.5. Предлагать улучшения работы чата.
 <div className="rule-subtitle"><h3>3. В чате запрещены:</h3></div>
<tr/>3.1. Обсуждение правил чата в чате.
<tr/>3.2. Мошенничество и запрещенные программы.
<tr/>3.3. Обсуждение пиратских ресурсов и публикации ссылок на них.
<tr/>3.4. Сообщения, содержащие в себе распространение и обсуждение запрещенных программ в чате.
<tr/>3.5. Категорически запрещено выдавать себя за представителя администрации чата или любой другой организации, без подтверждения этого статуса.
<tr/>3.6. Открытый мат.
<tr/>3.7. Дискриминация по расовому, национальному, религиозному или иному признаку / Националистические, расовые лозунги и высказывания.
<tr/>3.8. Сообщения, призывающие к каким-либо действиям против других национальностей.
<tr/>3.9. Сообщения, в которых говорится о превосходстве одной нации или народа над другими.
<tr/>3.10. Сообщения, ведущие к межнациональной, расовой ненависти и розни, а также ведущие к провоцированию конфликтов на религиозной почве.
<tr/>3.11. Пропаганда нетерпимости к какой-либо принадлежности, а также поощрение подобных действий и высказываний, совершаемых другими пользователями.
<tr/>3.12. Сообщения, содержащие в себе пропаганду насилия, оружия, рекламу и открытые обсуждения любых наркотических средств, а также пропаганда алкогольных напитков и табачных изделий, сообщения сексуально-порнографического характера, в том числе текстовые смайлы анатомического характера в чате запрещены
<tr/>3.13. Клевета, голословные обвинения (бездоказательные) / Распространение ложной информации.
<tr/>3.14. Запрещается прямо и косвенно оскорблять других пользователей в чате (использование литературных разговорных слов при оскорблении или хамстве пользователей чата), хамить пользователям и администрации чата, устраивать разборки/выяснения отношений.
<tr/>3.15. Флуд.<tr/>
<tr/>• отправка большого количества однотипных сообщений в течение 3 минут;
 <tr/>• использование более 3 (трех) любых символов или отдельных букв, повторение более 3 (трех) одинаковых слов подряд в одном сообщении;
 <tr/>• отправка нескольких сообщений, которые могут быть объединены в одно, написанных по одному слову или по одной букве.
 <tr/>3.16. Спам / Реклама / Ссылки
 <tr/>• коммерческая и некоммерческая реклама любых ресурсов без согласования с администрацией чата
 <tr/>• объявления частного характера, содержащие предложения на покупку\продажу любых товаров и/или услуг..
 <tr/>3.17. Троллинг — сообщения провокационного характера, написанные с целью вызвать конфликты, споры, взаимные оскорбления.
 <tr/>3.18. Обсуждение действий модераторов. Сообщения, содержащие в себе обсуждения действий Модератора и/или Администрации проекта или несогласие с ними.
 <tr/>3.19. Сообщения, разглашающие любую личную информацию участника, Модератора, Администрации проекта без его согласия.
 <tr/>3.20. Угрозы и оскорбления
 <tr/>3.21. Заниматься попрошайничеством
 <tr/>3.22. Нытье, гнусавость
 <div className="rule-subtitle"><h3>4. В чате не рекомендуется:</h3></div>
<tr/>4.1. Злоупотреблять «Caps Lock»ом.
<tr/>4.2. Злоупотреблять смайлами (например, отправлять 5 или более сообщений подряд, содержащих только смайлы).
<tr/>4.3. Отправлять объемные цитаты, в том числе порезанные на части в основной чат. При необходимости Вы можете указать ссылку на источник информации.
<tr/>4.4. Отправка нескольких сообщений, которые могут быть объединены в одно, написанных по одному слову или по одной букве.
<tr/>4.5.Наш чат — русскоязычный, следовательно, официальный язык общения – русский, не рекомендуются использовать при общении в нашем чате другие языки, допускаются исключения в некоторых случаях: написание текста на транслите, или международном языке (английский язык).
<tr/>4.6. Игнорировать предупреждения просьбы модераторов чата.


<div className="rule-subtitle"><h3>5. Права администратора:</h3></div>
<tr/>5.1. Если администратор или модератор считает, что какое-либо действие пользователя мешает остальным пользователям, он обязан предупредить пользователя о недопустимости такого поведения.
<tr/>5.2. В случае, если пользователь не прислушался к рекомендации, он может быть заблокирован на любой срок, который администратор или модератор посчитают справедливым.
<tr/>5.3. В случае выявления флуда или спама, модератор или администратор чата имеют право удалить сообщения пользователя из чата.
<tr/>5.4. Пользователь может обжаловать действия модератора в специальной теме форума, после чего в соответствии с правилами модераторов и администраторов будет вынесено соответствующее решение.
6. Правила поведения/работы в чате для модераторов/администраторов.
<tr/>6.1. Администраторы и модераторы следят за соблюдением правил чата.
<tr/>6.2. Администраторы и модераторы должны соблюдать правила чата.
<tr/>6.3. Администраторы и модераторы не должны обосновывать свои действия в чате.
<tr/>6.4. Администраторы и модераторы чата должны стремиться сделать общение в чате максимально комфортным для всех участников.
<tr/>6.5. Администраторы и модераторы могут принимать самостоятельные решения.
7. При контроле за соблюдением правил чата модераторы и администраторы могут осуществлять следующие действия:
<tr/>7.1. Предупреждение пользователя о недопустимости его поведения.
<tr/>7.2. Повторное предупреждение пользователя о недопустимости его поведения.
<tr/>7.3. Удаление сообщений, запрещенных п.3.
<tr/>7.4. Бан — запрет пользователю на доступ в чат.</div>
                </div>
            </div>
        </div>
    </div>
}

export default Community