import React, { useEffect } from "react";
import "../styles/academy.scss"
import img from "../icons/mainphoto.jpg"
import { useNavigate } from "react-router-dom";

function Academy() {

    const to = useNavigate();
  return (
    <div className="container" id="academy">
        <div className="academy-wrapper">
            <div className="academy-title">
                <h1>АКАДЕМИЯ</h1>
            </div>
            {/* <a className="academy-courses" href="https://t.me/+DcBi5PGFRSY1NDcy"> */}
            <a className="academy-courses" href="https://topgrussia.ru/community">
                <div className="row">
                    <div className="course-wrapper">
                        <div className="course img-1"/>
                    </div>
                    <div className="course-wrapper">
                        
                    <div className="course img-2"/></div>

                    {/* <div className="course-wrapper">
                        
                    <div className="course img-3"/></div> */}
                </div>
                <div className="row">
                    <div className="course-wrapper">
                        
                    <div className="course img-3"/> </div>
                    <div className="course-wrapper">
                        
                    <div className="course img-4"/></div>
                </div>

                <div className="row">
                    <div className="course-wrapper">
                        
                    <div className="course img-5"/> </div>
                    {/* <div className="course-wrapper">
                        
                    <div className="course img-4"/></div> */}
                </div>
            </a>
            <div className="academy-description">
                <p>Мы открываем вам доступ к передовым материалам по заработку в различных сферах:
                коммерция, предпринимательство, товарный бизнес, создание карьеры и контент-мэйкинг.</p>
                {/* <p>G Club открыает вам доступ к мировым иноостранным экспепертам в своем бизнесеа, предпренимательстве, создании карьеры и биохакинге.</p> */}
            
           </div> {/* 
            <div className="academy-description">
                <h3>ЭКСКЛЮЗИВНЫЕ ИНТЕРВЬЮ</h3> */}
                {/* <p>G Club открыает вам доступ к мировым иноостранным экспепертам в своем бизнесеа, предпренимательстве, создании карьеры и биохакинге.</p> */}
            
            {/* </div>
            <img src={img} className="interviewimg"/> */}
            
        </div>
    </div>
  );
}

export default Academy;
