import React, { useEffect } from "react";
import alex from "../icons/alex.jpeg"
import "../styles/tg.scss"

function TG() {
  return (
    <div className="container">
        <a className="tg-wrapper" href="https://t.me/escapevector">
            <div className="img"/>
            {/* <h1 className="title">АКАДЕМИЯ</h1>
            <div className="button-wrapper">
                <div className="button primary">УЗНАТЬ БОЛЬШЕ</div>
            </div> */}
        </a>
    </div>
  );
}

export default TG;
