import React from "react";
import NavbarNET from "../../Components/NavbarNET";
import Footer from "../../Components/Footer";
import NetworkHead from "../../Components/NetworkHead";
import NetworkDesc from "../../Components/NetworkDesc";
import "./index.scss"

function NetworkPage() {
    

    return [<NavbarNET buttons={false}/>, <NetworkHead/>, 
    <NetworkDesc/>, 
    // <Footer/>
]
}

export default NetworkPage