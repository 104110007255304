import Academy from "../Components/Academy";
import Carousel from "../Components/Carousel";
import ComingSoon from "../Components/ComingSoon";
import Footer from "../Components/Footer";
import Heading from "../Components/Heading";
import Navbar from "../Components/Navbar";
import Network from "../Components/Network";
import Socproof from "../Components/socproof";
import TG from "../Components/TG";
import Arrow from "../icons/arrow";
import "../styles/Main.scss";
import React, { useEffect } from "react";


function Main() {
  return [<Navbar/>, <Heading/>, <Socproof/>, <TG/>,
    <Arrow/>, <Carousel/>,
    <Arrow/>, <Network/>,
    <Arrow/>,   <Academy/>, 
  // <Network/>, 
  // <ComingSoon/>, 
  <Footer/>]
}

export default Main;
