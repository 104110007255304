import React, { useEffect } from "react";
import alex from "../icons/alex.jpeg"
import "../styles/socproof.scss"

function Socproof() {
  return (
    <div className="container">
        <div className="socproof-wrapper" >
            <a className="sm" href="https://instagram.com/vector.network"><img src="/instagram.png"/>13K<br/>INSTA</a>
            <a className="sm" href="https://youtube.com/@topgrussia" ><img src="/youtube.png"/>1.5к<br/>YT</a>
            <a className="sm" href="https://t.me/escapevector"><img src="/telegram.png"/>1.3K<br/>TG</a>
            <a className="sm" href="/community"><img src="/community.png"/>750<br/>Community</a>
            
        </div>
    </div>
  );
}

export default Socproof;
