import React, { useEffect } from "react";
import alex from "../icons/alex.jpeg"
import "../styles/networkdesc.scss"
import Arrow from "../icons/arrow";

function NetworkDesc() {
  return (
    <div className="container" id="network">
        <div className="networkdesc-wrapper" href="#academy">
            <Arrow/>
              <div className="networkdesc-title"><h2>стань частью большой истории</h2></div>
            <div className="networkdesc-block">
            <div className="networkdesc-block-wrapper">
                <img className="networkdesc-image" src="https://i.pinimg.com/736x/96/8c/36/968c3669be26568747a336f86516a93d.jpg"/>
                <div className="networkdesc-description">
                  <p className="description">получай. создавай. зарабатывай.
сыграй свою роль в сообществе единомышленников.
ничего лишнего - только ты и правильное окружение.  
</p>
                </div>
              </div>
            </div>
            <Arrow/>
              <div className="networkdesc-title"><h2>учись зарабатывать быстро</h2></div>
            <div className="networkdesc-block">
            <div className="networkdesc-block-wrapper">
                <img className="networkdesc-image"  src="https://i.pinimg.com/736x/d9/79/76/d979768fa07c8618b7c2893aac66fd39.jpg"/>
                <div className="networkdesc-description">
                  <p className="description">скорость - основа результата.
узнай о самых быстрых методах заработка у нас
на образовательной платформе. 
</p>
                </div>
              </div>
            </div>
            <Arrow/>
              <div className="networkdesc-title"><h2>мы не продаем курсы</h2></div>
            <div className="networkdesc-block">
            <div className="networkdesc-block-wrapper">
                <img className="networkdesc-image" src="https://i.pinimg.com/736x/28/e5/3e/28e53ec25d42e0fe3a618851510d8b9e.jpg"/>
                <div className="networkdesc-description">
                  <p className="description">вся продукция сообщества, включая услуги, 
является бесплатной. экономика внутри сообщества 
строится на токене. больше работаешь - больше получаешь,
все справедливо.  
</p>
                </div>
              </div>
            </div>
            <Arrow/>
              <div className="networkdesc-title"><h2>money talks</h2></div>
            <div className="networkdesc-block">
            <div className="networkdesc-block-wrapper">
                <img className="networkdesc-image" src="/moneytalks.jpg"/>
                <div className="networkdesc-description">
                  <p className="description">общайся и обменивайся опытом с другими участниками.
мероприятия, чаты, ценная информация. не упускай свой
шанс стать частью самого инновационного сообщества снг. 
</p>
                </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default NetworkDesc;
