import React, { useEffect } from "react";
import alex from "../icons/alex.jpeg"
import "../styles/carousel.scss"

function Carousel() {
  return (
    <div className="container">
        <a className="carousel-wrapper" href="#academy">
            <div className="img"/>
            {/* <h1 className="title">АКАДЕМИЯ</h1>
            <div className="button-wrapper">
                <div className="button primary">УЗНАТЬ БОЛЬШЕ</div>
            </div> */}
        </a>
    </div>
  );
}

export default Carousel;
