import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Token from "../../Components/Token";
import "./index.scss"

function TokenPage() {
    

    return [ <Token/>, <Footer/>]
}

export default TokenPage