import React from "react"
import img from "../icons/tate_em.jpg"


function Community() {
    return <div className="container" id="token">
        <div className="token-wrapper">
            <div className="token-title">
                <h1>TOKEN</h1>
            </div>
            <div className="token-courses">
                {/* <div className="row">
                    <div className="course-wrapper">
                        <div className="course img-1"/>
                    </div>
                    <div className="course-wrapper">

                    <div className="course img-2"/></div>
                </div> */}
                {/* <div className="row">
                    <div className="course-wrapper">

                    <div className="course img-3"/> </div>
                    <div className="course-wrapper">

                    <div className="course img-4"/></div>
                </div> */}
            </div>
            <div className="token">
                <p>ПРИШЛО ВРЕМЯ УСЕРДНОЙ РАБОТЫ.</p>
                {/* <p>G Club открыает вам доступ к мировым иноостранным экспепертам в своем бизнесеа, предпренимательстве, создании карьеры и биохакинге.</p> */}

            </div>
            <div className="token-description">
                <img src={"g.mov"} className="g-image"/>
                {/*<h3>Ваш Mr. G</h3>*/}
                {/* <p>G Club открыает вам доступ к мировым иноостранным экспепертам в своем бизнесеа, предпренимательстве, создании карьеры и биохакинге.</p> */}

            </div>
            <div className="button-wrapper token-button">
                <a className="button" id={"channel"} href="https://t.me/andrewtate_officiall"><p>КАНАЛ Mr. G</p></a>
                <a className="button" href="https://t.me/topgtoken_bot"><p>БОТ Mr. G</p></a>
            </div>
            <div className="rules">
                <h3 className="token-title">WHITEPAPER</h3>
                {/* <p>G Club открыает вам доступ к мировым иноостранным экспепертам в своем бизнесеа, предпренимательстве, создании карьеры и биохакинге.</p> */}
                <div className="token-section">
                    <h3 className="token-title">Введение</h3>
                    <div className="token-description">
                        <p>Современное общество сталкивается с растущей проблемой снижения продуктивности и
                            вовлеченности в рабочий процесс. Это связано с увеличением отвлекающих факторов, таких как
                            социальные сети, цифровые развлечения и постоянный поток информации. Люди забывают о своих реальных обязанностях и целях.</p>
                            <p>Эта тенденция требует незамедлительного решения, так как дальнейшее углубление в
                            “потребление” грозит серьезными последствиями для производительности труда и общего
                            благополучия общества. "TOP G WORLD" направлен на преодоление этой проблемы путем создания
                            эффективных инструментов и стратегий для повышения мотивации и продуктивности, а также
                            развития осознанного потребления цифрового контента.</p>
                            <p>Проект TOP G предлагает инновационное решение проблемы мотивации и продуктивности среди
                            молодых людей путем введения токена TOP G. Этот токен станет стимулом для создания
                            качественного контента и активного участия в экономической деятельности.</p>
                        <p>
                            В этом документе излагаются философские основы, технические основы и экономические механизмы
                            “TOP G”.
                        </p>
                    </div>
                </div>
                <div className="token-section">
                    <h3 className="token-title">Экономика</h3>
                    <div className="token-description">
                        <h3 className="token-subtitle">Что такое TOP G token?</h3>
                        <p>TOPGTOKEN является неотъемлемой частью платформы “Top G”, и мы работаем над созданием
                            ключевых механизмов, которые будут неразрывно связаны с платформой и ее ценностями.</p>
                        <p>1. Мотивация через вознаграждение</p>
                        <p>Токен TOP G предоставит молодым людям возможность зарабатывать, создавая и делясь контентом.
                            Участники будут получать токены за публикацию интересных и полезных материалов, привлечение
                            аудитории и активное взаимодействие с другими пользователями. Это создаст мощный стимул для
                            творческой деятельности и самовыражения.</p>
                        <p>2. Экономическая выгода</p>
                        <p>TOP G токен будет использоваться как валюта внутри экосистемы проекта, что позволит молодым
                            людям монетизировать свой контент, получать реальный доход. Пользователи смогут обменивать
                            токены на товары и услуги, либо конвертировать их в другие криптовалюты или фиатные
                            деньги.</p>
                        <p>3. Создание сообщества</p>
                        <p>Проект будет способствовать формированию активного и поддерживающего сообщества, где молодые
                            люди смогут делиться своими идеями, получать обратную связь и вдохновение. У каждого
                            участника платформы будет возможность заработать свой “статус”.</p>
                        <p>Проект TOP G, таким образом, не только решает проблему мотивации и продуктивности, но и
                            создает новую экономическую и социальную экосистему, которая помогает молодым людям
                            реализовать свой потенциал и достичь финансовой независимости через создание и монетизацию
                            контента.</p>
                        <p>TOPG - это токен TON utility, построенный на блокчейне TON (The Open Network), который служит
                            основой для транзакций в “Мире TOPG” и имеет следующие виды применения:</p>

                        <p>• <a href={'#governance'}>Governance</a></p>
                        <p>•  <a href={'#w2e'}>Work-To-Earn model</a></p>
                        <p>• <a href={'#fcm'}>Модель комиссионных сборов</a></p>

                        <h3 className="token-subtitle" id={'governance'}>Governance.</h3>
                        <p>Владея токеном TOPG на сумму более 20000 “Top G World”, вы становитесь участником Governance,
                            это дает вам доступ к закрытому сообществу в Telegram и возможность получать вознаграждения
                            за управление. Одной из важных целей, для которой был создан токен TOPG, является
                            децентрализованное голосование. Он позволяет владельцам участвовать в принятии
                            управленческих решений платформы, используя структуру DAO. Весомость вашего решения при
                            голосовании зависит от количества токенов, которыми вы владеете. Члены правления могут
                            объединяться и сотрудничать при принятии инвестиционных решений. Кроме того, мы используем
                            объединенные знания ведущих игроков для принятия консенсусных решений для расширения
                            сообщества.</p>
                        <p>Любой игрок, имеющий статус ___ , становится участником Governance
                        </p>

                        <h3 className="token-subtitle" id={"w2e"}>Work-To-Earn model.</h3>
                        <p>Игры, основанные на блокчейне, могут одновременно способствовать монетизации развлечений. Но
                            в “Top G World” используется модель "работай, чтобы заработать". На платформе игрок может
                            выполнять квесты и испытания, развивая свое мышление, физическое и ментальное состояние,
                            узнавать новое. Также существует множество внутриигровых и внеигровых розыгрышей призов, где
                            игроки могут выиграть токен TOPG и продать или использовать его.</p>

                        <h3 className="token-subtitle" id={"fcm"}>Модель комиссионных сборов.</h3>
                        <p>Модель сбора комиссионных включает в себя комиссию в размере 10% от всех транзакций
                            покупки/продажи. Комиссия включает в себя:
                            <p>• Руководство: 7% для участников руководства</p>
                            <p>• Ликвидность токена: 1% используется для повышения цены TOPGTOKEN</p>
                            <p>• Фонд: 2% предлагает гранты. Для погружения в децентрализованный виртуальный мир
                                разработчики
                                и сотрудники работают над тем, чтобы игроки и участники процесса управления получали
                                контент
                                высочайшего качества.</p>

                        </p>
                        <h3 className="token-subtitle">Награды.</h3>
                        <p>Существует несколько видов вознаграждений:
                            <p>• Governance: от всех транзакций Governance получает 10%, которые распределяются между
                                участниками Governance. Вознаграждение участника можно получить через 2 недели владения
                                токеном. (Если в какой-то момент у участника будет менее 1000 токенов TOPG, а
                                накопленные вознаграждения составят менее 1 процента, вознаграждения будут направлены на
                                раздачу призов)
                            </p><p>• Ежедневные квесты и испытания: каждый день сообщество “Top G World” получает
                                задания и испытания, в которых вы можете получить токены TOPG
                            </p><p>• Розыгрыши призов: там часто проводятся розыгрыши призов, в которых вы можете
                                получить TOPG
                            </p>
                        </p>
                    </div>
                    <div className={"token-section"}>
                        <h3 className={"token-title"}>
                            Концепт
                        </h3>
                        <div className="token-description">
                            <p>В современном мире интернет пространство занимает чуть ли не большую часть нашей жизни.
                            </p><p>Такие платформы как instagram, tiktok, youtube зарабатывают огромные деньги, забирая
                            на себя внимание современного общества.
                        </p>     <p>Множество людей отвлеченно от реальности. Предпочитают дешевый дофамин от
                            псевдо-достижений виртуального мира. Становятся жертвами влияния со стороны нежелательных
                            организаций и движений. Виртуальный мир постепенно заменяет нам реальность. Извращение
                            традиционных ценностей местами делает общество уязвимым. Мужество, честь и достоинство может
                            быть продемонстрировано только вживую. РЕАЛЬНЫЕ ДЕЙСТВИЯ.
                        </p>        <p>Интернет пространство сочетает в себе как полезную информацию от хороших людей.
                            Так и вредное воздействие от недобросовестных обывателей. Исключить что-либо полностью -
                            невозможно.
                        </p>             <p>Организация Top G World считает, что данный процесс логичный и вытекает из
                            принципов природы человеческого общества.
                        </p>                <p>Сохранение устоявшихся принципов и защита общества от разрушительного
                            влияния как отдельных индивидуумов, так и организаций - возможна лишь путем здравой
                            конкуренции, путем продвижения в массы верных идей.
                        </p>                <p>Top G World - платформа, готовая взять на себя эту ответственность.
                            Участников сообщества объединяет вера в общие принципы. Среди них: стремление к
                            саморазвитию, совершенствование ментального и физического состояния, защита традиционных
                            ценностей, желание узнавать новое. В рамках около-игровой внутри конкурентной системы
                            участники узнают о способах заработка, спорте, психологии, навыках общения и духовном
                            развитии. Общение между участниками укрепляет сплоченность и веру коллектива.
                        </p>                   <p>Мы строим счастливое будущее. Победа будет за нами!
                        </p>
                        </div>
                    </div>

                    <div className={"token-section"}>
                        <h3 className={"token-title"}>
                            Заключение
                        </h3>
                        <div className="token-description">
                            <p>“TOPG World” - это децентрализованная образовательно-мотивационная платформа. Ваш отец
                                “Мистер Джи” расскажет о различных видах хаслерства, раскроет секреты бизнеса и посвятит
                                в принципы спорта и духовного развития. Платформа научит вас различным способам
                                заработка. Инвесторы могут присоединиться к приватному Руководству, чтобы участвовать в
                                разработке платформы и общаться с Mr G.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Community