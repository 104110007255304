import React, { useEffect } from "react";
import alex from "../icons/alex.jpeg"
import "../styles/network.scss"

function Network() {
  return (
    <div className="container">
        <a className="network-wrapper" href="/network">
            <div className="img"/>
            {/* <h1 className="title">АКАДЕМИЯ</h1>
            <div className="button-wrapper">
                <div className="button primary">УЗНАТЬ БОЛЬШЕ</div>
            </div> */}
        </a>
    </div>
  );
}

export default Network;
